import logo from './logo.svg';
import './App.css';
import {Button, Alert, Breadcrumb, Card, Container} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import reportWebVitals from "./reportWebVitals";
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Card style={{ color: 'white', backgroundColor: 'red' }}>
          <Card.Img className="App-logo" src={logo} />
          <Card.Body>
            <Card.Title className="App-title">title of the card</Card.Title>
            <Card.Text>Text body inside</Card.Text>
            <Button variant="primary" onClick={() => reportWebVitals()}>Read more</Button>
          </Card.Body>
          <Card.Footer></Card.Footer>

        </Card>
        <Breadcrumb>
          <Breadcrumb.Item>test 1</Breadcrumb.Item>
          <Breadcrumb.Item>test 2</Breadcrumb.Item>
          <Breadcrumb.Item active={true}>test 3</Breadcrumb.Item>
        </Breadcrumb>
        <Alert variant={"danger"}>Alert here</Alert>
        <Button>Here button</Button>
      </header>
    </div>
  );
}

export default App;
